<template>
  <h2 class="cover">
    <span v-if="strapline !== 'undefined'">{{ strapline }}&nbsp;</span><span v-html="formatTitle"></span>
    <div class="cover_image">
      <img :src="image" v-show="image !== 'undefined'" />
    </div>
    <div class="cover_gradient"></div>
  </h2>
</template>

<script>
export default {
  name: 'mac-header',
  props: ['title', 'image', 'strapline'],
  computed: {
    formatTitle() {
      if (!this.title) {
        return;
      }
      return this.title.replace(/{/g, '<').replace(/}/g, '>');
    },
  },
};
</script>
<style scoped lang="scss"></style>
